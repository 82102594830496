import moment from 'moment';

export const MAX_B64_FILE_SIZE = 5242880; // 5MB
export const BACKEND_DATETIME_FORMAT = moment.ISO_8601;

// Django username regex
export const R_USERNAME = /^[\w@+.-]+$/i;
// NOTE: Unsafe regex shouldn't matter too much because this is used just for frontend validation, right?
export const R_EMAIL = /^[\w+.-]+@([a-z0-9-]+\.)+[a-z0-9-]{2,63}$/i; // eslint-disable-line security/detect-unsafe-regex
// Backend's password reset UUID4 token
export const R_PW_RESET_TOKEN = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

// eslint-disable-next-line max-len
export const LEAFLET_ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
export const LEAFLET_TILELAYER_URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png';

export const USER_TYPE = Object.freeze({ UNAUTHENTICATED: 0, PERSON: 1, OPERATOR: 2 });

// `SERVER` value is prepended to all API calls in src/middleware/api.js
export const SERVER = (process.env.NODE_ENV === 'production' && window.react?.REACT_APP_API_URL)
    ? window.react.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;

// `APP_NAME` value is used for selecting the login page for PERSON or OPERATOR (both in local)
export const APP_NAME = (process.env.NODE_ENV === 'production' && window.react?.REACT_APP_APP_NAME)
    ? window.react.REACT_APP_APP_NAME
    : process.env.REACT_APP_APP_NAME;

// `SHOP_ID_MARKER` in endpoint is automatically replaced with the currently selected shop's ID in src/middleware/api.js
export const SHOP_ID_MARKER = ':SHOP_ID';

export const METHODS = Object.freeze(['GET', 'DELETE', 'POST', 'PATCH', 'PUT']);

export const FORCE_LOGOUT_CODES = Object.freeze([
    'REFRESH_TOKEN_EXPIRED',
    'REFRESH_TOKEN_DATA_INVALID',
    'INVALID_HEADER',
]);


export const OPENING_HOURS_FIELDS = Object.freeze([
    'opening_hours_mon',
    'opening_hours_tue',
    'opening_hours_wed',
    'opening_hours_thu',
    'opening_hours_fri',
    'opening_hours_sat',
    'opening_hours_sun',
]);

export const SHOP_BLANK = Object.freeze({
    name: '',
    email: '',
    phone: '',
    opening_hours_mon: '',
    opening_hours_tue: '',
    opening_hours_wed: '',
    opening_hours_thu: '',
    opening_hours_fri: '',
    opening_hours_sat: '',
    opening_hours_sun: '',
    location: {},
    user_ids: [],
    is_private: false,
});

export const DEFAULT_MAP_COORDS = Object.freeze([60.218099, 24.878941]);

export const COMPANY_BLANK = Object.freeze({
    name: '',
    email: '',
    phone: '',
    contact_person: '',
    location: {},
    users: [],
    shop_ids: [],
});
